import React from 'react';
import { isAndroid, isIOS } from 'react-device-detect';

import { DialogContext } from '@cvt/contexts';
import { Permissioned } from '@cvt/components/Permissioned';

import { Authenticated } from '@modules/Auth/components';
import { ConfimDeletionDialog } from '@modules/Users/dialogs/ConfimDeletionDialog';
import { CreateTripDialog } from '@modules/Trips/dialogs/CreateTripDialog';
import { EditTripDialog } from '@modules/Trips/dialogs/EditTripDialog';
import { CreateContactDialog } from '@modules/Contacts/dialogs/CreateContactDialog';
import { EditContactDialog } from '@modules/Contacts/dialogs/EditContactDialog';
import { ViewContactDialog } from '@modules/Contacts/dialogs/ViewContactDialog';
import { ViewPictureDialog } from '@modules/Contacts/dialogs/ViewPictureDialog';
import { CreateCommunityDialog } from '@modules/Communities/dialogs/CreateCommunityDialog';
import { EditCommunityDialog } from '@modules/Communities/dialogs/EditCommunityDialog';
import { InviteCommunityMembersDialog } from '@modules/Communities/dialogs/InviteCommunityMembersDialog';
import { CreateChannelDialog } from '@modules/Communities/dialogs/CreateChannelDialog';
import { EditChannelDialog } from '@modules/Communities/dialogs/EditChannelDialog';
import { CreateEventDialog } from '@modules/Events/dialogs/CreateEventDialog';
import { EditEventDialog } from '@modules/Events/dialogs/EditEventDialog';
import { InviteCommunityChannelMembersDialog } from '@modules/Communities/dialogs/InviteCommunityChannelMembersDialog';
import { CreateChatDialog } from '@modules/Messaging/dialogs/CreateChatDialog';
import { UserQrCodeDialog } from '@modules/Users/dialogs/UserQrCodeDialog';
import { UserDialog } from '@modules/Users/dialogs/UserDialog';
import { UserChatDialog } from '@modules/Users/dialogs/UserChatDialog';
import { TripNotificationEditDialog } from '@modules/Trips/dialogs/TripNotificationEditDialog';
import { EventDialog } from '@modules/Events/dialogs/EventDialog';

import { isNative } from '@shared/helpers/environment';
import { SearchDialog } from '@shared/dialogs/SearchDialog';

import { MobileAppDialog } from './MobileAppDialog';

export const Dialogs = () => {
  const { openedDialogs, closeDialog, dialogOptions } = React.useContext(DialogContext);
  const [nativeMobileAppDialog, setNativeMobileAppDialog] = React.useState(false);

  React.useEffect(() => {
    if (!isNative() && (isAndroid || isIOS)) {
      setNativeMobileAppDialog(true);
    }
  }, []);

  return (
    <React.Fragment>
      <Authenticated>
        <Permissioned permission="users.pro">
          <MobileAppDialog open={nativeMobileAppDialog} onClose={() => setNativeMobileAppDialog(false)} />
        </Permissioned>
        <SearchDialog open={!!openedDialogs['search']} onClose={() => closeDialog('search', dialogOptions['search']?.replace)} />
        <CreateTripDialog open={!!openedDialogs['createTrip']} onClose={() => closeDialog('createTrip', dialogOptions['createTrip']?.replace)} />
        <EditTripDialog open={!!openedDialogs['editTrip']} onClose={() => closeDialog('editTrip', dialogOptions['editTrip']?.replace)} data={dialogOptions['editTrip']} />
        <CreateContactDialog open={!!openedDialogs['createContact']} onClose={() => closeDialog('createContact', dialogOptions['createContact']?.replace)} />
        <CreateCommunityDialog open={!!openedDialogs['createCommunity']} onClose={() => closeDialog('createCommunity', dialogOptions['createCommunity']?.replace)} />
        <EditCommunityDialog open={!!openedDialogs['editCommunity']} onClose={() => closeDialog('editCommunity', dialogOptions['editCommunity']?.replace)} data={dialogOptions['editCommunity']} />
        <CreateEventDialog open={!!openedDialogs['createEvent']} onClose={() => closeDialog('createEvent', dialogOptions['createEvent']?.replace)} data={dialogOptions['createEvent']} />
        <EditEventDialog open={!!openedDialogs['editEvent']} onClose={() => closeDialog('editEvent', dialogOptions['editEvent']?.replace)} data={dialogOptions['editEvent']} />
        <InviteCommunityMembersDialog open={!!openedDialogs['inviteCommunityMembers']} onClose={() => closeDialog('inviteCommunityMembers', dialogOptions['inviteCommunityMembers']?.replace)} data={dialogOptions['inviteCommunityMembers']} />
        <CreateChannelDialog open={!!openedDialogs['createCommunityChannel']} onClose={() => closeDialog('createCommunityChannel', dialogOptions['createCommunityChannel']?.replace)} data={dialogOptions['createCommunityChannel']} />
        <EditChannelDialog open={!!openedDialogs['editCommunityChannel']} onClose={() => closeDialog('editCommunityChannel', dialogOptions['editCommunityChannel']?.replace)} data={dialogOptions['editCommunityChannel']} />
        <InviteCommunityChannelMembersDialog open={!!openedDialogs['inviteCommunityChannelMembers']} onClose={() => closeDialog('inviteCommunityChannelMembers', dialogOptions['inviteCommunityChannelMembers']?.replace)} data={dialogOptions['inviteCommunityChannelMembers']} />
        <EditContactDialog open={!!openedDialogs['editContact']} onClose={() => closeDialog('editContact', dialogOptions['editContact']?.replace)} data={dialogOptions['editContact']} />
        <ViewContactDialog open={!!openedDialogs['viewContact']} onClose={() => closeDialog('viewContact', dialogOptions['viewContact']?.replace)} data={dialogOptions['viewContact']} />
        <ViewPictureDialog open={!!openedDialogs['viewPicture']} onClose={() => closeDialog('viewPicture', dialogOptions['viewPicture']?.replace)} data={dialogOptions['viewPicture']} />
        <CreateChatDialog open={!!openedDialogs['createChat']} onClose={() => closeDialog('createChat', dialogOptions['createChat']?.replace)} />
        <ConfimDeletionDialog open={!!openedDialogs['confirmDeleteProfile']} onClose={() => closeDialog('confirmDeleteProfile', dialogOptions['confirmDeleteProfile']?.replace)} data={dialogOptions['confirmDeleteProfile']} />
        <UserQrCodeDialog open={!!openedDialogs['userQrCode']} onClose={() => closeDialog('userQrCode', dialogOptions['userQrCode']?.replace)} data={dialogOptions['userQrCode']} />
        <UserDialog open={!!openedDialogs['viewUser']} onClose={() => closeDialog('viewUser', dialogOptions['viewUser']?.replace)} data={dialogOptions['viewUser']} />
        <UserChatDialog open={!!openedDialogs['userChat']} onClose={() => closeDialog('userChat', dialogOptions['userChat']?.replace)} data={dialogOptions['userChat']} />
        <TripNotificationEditDialog open={!!openedDialogs['tripNotificationEdit']} onClose={() => closeDialog('tripNotificationEdit', dialogOptions['tripNotificationEdit']?.replace)} data={dialogOptions['tripNotificationEdit']} />
        <EventDialog open={!!openedDialogs['eventView']} onClose={() => closeDialog('eventView', dialogOptions['eventView']?.replace)} data={dialogOptions['eventView']} />
      </Authenticated>
    </React.Fragment>
  );
};
  