import React from 'react';

export const dictionary = {
  header: {
    switchToDarkMode: 'Dark mode',
    switchToLightMode: 'Light mode',
  },

  auth: {
    login: {
      title: 'Login',

      buttonLogin: 'Login',
      buttonLoginWithApple: 'Login with Apple',
      buttonLoginWithGoogle: 'Login with Google',
      buttonLoginWithMicrosoft: 'Login with Microsoft',
      buttonLoginWithFacebook: 'Login with Facebook',
      buttonLoginPhone: 'Login with Phone',
      buttonSendSmsSignupPhone: 'Send SMS code',
      buttonApplyToJoin: 'Apply to join',
      buttonResetPassword: 'Reset password',
      dontHaveAccount: 'Don\'t have an account?',
    },

    signUp: {
      title: 'Sign up',

      termsOfService: 'Terms of Service',
      privacyPolicy: 'Privacy Policy',
      acceptTermsAndConditions: (terms: React.ReactNode, privacy: React.ReactNode) => <React.Fragment>I accept the {terms} and {privacy}</React.Fragment>,

      buttonSignup: 'Sign up',
      buttonSignupWithApple: 'Continue with Apple',
      buttonSignupWithGoogle: 'Continue with Google',
      buttonSignupWithMicrosoft: 'Continue with Microsoft',
      buttonSignupWithFacebook: 'Continue with Facebook',
      buttonSignupManually: 'Continue manually',
      buttonSignupPhone: 'Continue with Phone',
      buttonSendSmsSignupPhone: 'Send SMS code',
      buttonLogin: 'Login',
      alreadyHaveAccount: 'Already have an account?',
    },

    resetPassword: {
      title: 'Reset password',

      buttonLogin: 'Login',
      buttonReset: 'Reset password',

      passwordResetEmailSent: 'You will receive a password reset link if this email is registered.',
    },

    validations: {
      userWithThisEmailAlreadyExists: 'User with this email already exists',
      userNotFound: 'User not found',
      passwordsDoNotMatch: 'Passwords do not match',
      emailOrPasswordWrong: 'Email or password is incorrect',
      thePasswordMustBeAtLeast8CharactersLong: 'The password must be at least 8 characters long',
    },

    impersonate: {
      noToken: 'Token is not present',
      invalidToken: 'Token is invalid or expired',
    },

    firebase: {
      errors: {
        'auth/account-exists-with-different-credential': 'This account already exists with a different login provider',
      },
    },
    divider: 'or',

    phoneVerification: {
      title: 'Verify Your Account',
      requiredVerificationTitle: 'We\'re upgrading our security',
      requiredVerificationSubtitle: 'Please verify your account in order to continue using the app',
      buttonVerifySmsPhone: 'Verify',
      buttonSendSmsPhone: 'Send SMS code',
    },

    subscriber: {
      title: (firstName: string) => `Know when ${firstName} will be in town next`,
    },
  },

  home: {
    map: {
      clusterList: {
        title: 'Users in this area',
      },
      geolocation: {
        geolocationPermissionConfirmation: 'Share your location to display it on the map. Your location is not shared with anyone.',
        geolocationPermissionButtonOk: 'Ok',
        geolocationPermissionButtonConfirm: 'Open settings',
      },
    },
  },

  users: {
    labelTypeCustomer: 'Customer',
    labelTypeAdmin: 'Admin',
    labelTypeStaff: 'Staff',

    invite: {
      dialog: {
        title: 'Invite team members',
        placeholderEmail: 'Email, comma separated',
        buttonInvite: 'Send invite',
        invitationSentInfo: (email: string) => `Invitation to ${email} sent`,
      },

      title: 'Your personal invitation to THR',
      subtitle: (name?: string) => 'Sign up and confirm your location',
      inviteButton: 'Invite Team Member',
    },

    list: {
      title: 'Network',
    },

    create: {
      title: 'Create User',
      buttonCreate: 'Create user',
    },

    edit: {
      buttonEdit: 'Edit user',
      buttonDelete: 'Delete user',
      deleteConfirmation: 'Are you sure you want to delete this user?',
    },

    onboarding: {
      buttonContinue: 'Continue',

      profileStep: {
        title: 'Profile',
        subtitle: <>Finish setting up your profile before<br/>seeing the people who already know you</>,

        phoneHelperText: 'Used to verify you as a real user',
        baseLocationHelperText: 'When not travelling, others will see you here',
        missingProfilePictureConfirmationTitle: 'Are you sure you want to proceed without a profile picture?',
      },

      subscriberProfileStep: {
        title: 'Details',
        subtitle: 'Complete your subscription',
      },

      contactsStep: {
        title: 'See where your contacts are',
        subtitleImport: 'They won\'t be notified',
        mapAlert: 'Contacts with location will appear on the map',

        buttonImport: 'Upload Contacts & Get Started',
        buttonLocateContact: 'Set location',
      },

      tripStep: {
        title: 'Create Your Next Trip',
        subtitle: 'Let your network know when you\'re in town!',

        mapAlert: 'When travelling, other users will see your pin here.',
      },
    },

    profile: {
      privacy: {
        title: 'Visibility',
      },
      notifications: {
        title: 'Notifications',
      },

      profileLinkCopied: 'Profile link copied',

      deleteConfirmation: 'Are you sure you want to delete your account?',

      clipboardSaved: (name: string) => `${name} has been saved to clipboard`,
    },

    connection: {
      buttonBlock: 'Block',
      deleteConfirmation: 'Are you sure you want to block this user?',
      newStatusLabel: 'New',
    },
  },

  trips: {
    list: {
      title: 'My Trips',
      upcomingtTitle: 'Upcoming Trips',
    },

    create: {
      title: 'Add Trip',
      buttonCreate: 'Add trip',

      notifyDialogTitle: 'Trip added successfully',
      notifyDialogDescription: 'Let your network know about your trip!',
      notifyCancelLabel: 'Next time',
      notifyConfirmLabel: 'Notify',
    },

    edit: {
      title: 'Edit Trip',
      buttonEdit: 'Save',
      buttonNotify: 'Notify',
      buttonConfirm: 'Confirm Now',
      buttonDelete: 'Delete',
      deleteConfirmation: 'Are you sure you want to delete this trip?',

      notifyDialogTitle: 'Trip edited successfully',
      notifyDialogDescription: 'Let your network know about your trip!',
      notifyCancelLabel: 'Next time',
      notifyConfirmLabel: 'Notify',
      upcomingTrip: 'Upcoming trip',
      startDate: 'Arrival date',
      endDate: 'Departure date',
      notifiedContacts: 'The contacts you selected have been notified',
    },

    newStatusLabel: 'New',
    generatedStatusLabel: 'To Confirm',
    generatedAlert: 'This trip was created automatically from your Gmail integration',
    notConfirmedAlert: 'This trip needs to be confirmed before it\'s visible to others',
  },

  contacts: {
    list: {
      title: 'Contacts',
      invitationSent: 'Invitation sent',
      buttonInviteEveryone: 'Invite all',
      inviteContactsConfirmation: 'You\'re about to invite everyone in your contacts list',
      inviteContactsConfirmLabel: 'Invite',
      buttonViewLinkedUser: 'View user',
    },

    import: {
      title: 'Importing contacts',
      importConfirmation: 'We detected new contacts in your network, would you like to import them?',
      importContactsPermissionConfirmation: 'Contacts access',
      importContactsPermissionDescription: (privacy: React.ReactNode) => <React.Fragment>
        Thr. collects your contact data and syncs it with Thr. servers. We use your contacts to organise and display your network on your map of connections and help you manage your relationships.
        See our {privacy}.
      </React.Fragment>,
      importContactsPermissionButtonSkip: 'Not now',
      importContactsPermissionButtonConfirm: 'Agree',
    },

    create: {
      title: 'Add Contact',
      buttonCreate: 'Add contact',
    },

    edit: {
      title: 'Edit Contact',
      buttonEdit: 'Save',
      buttonDelete: 'Delete',
      deleteConfirmation: 'Are you sure you want to remove this contact?',
    },

    view: {
      title: 'Contact',
      buttonEdit: 'Edit',
      buttonInvite: 'Invite',
    },

    invite: {
      inviteMessage: 'Invite',
      inviteConfirmation: (name: string) => `Are you sure you want to invite ${name}`,
      inviteConfirmationMessage: 'We will send this contact a SMS from your own phone.',
    },

    newStatusLabel: 'New',
    notifyButton: 'Notify',
  },

  communities: {
    list: {
      title: 'Networks',
    },

    create: {
      title: 'New Network',
      buttonCreate: 'Create',

      selectedMembersTitle: 'Selected Members',
    },

    edit: {
      title: 'Edit Community',
      buttonEdit: 'Save',
      buttonDelete: 'Delete',
      deleteConfirmation: 'Are you sure you want to delete this community?',
      rejectConfirmation: 'Are you sure you want to reject the invitation to this community?',
      leaveConfirmation: 'Are you sure you want to leave this community?',
      buttonDeleteMember: 'Remove Member',
      promoteMember: 'Promote to Admin',
      promoteMemberConfirmation: 'Are you sure you want to promote this Member to Admin?',
      demoteMember: 'Demote from Admin',
      demoteMemberConfirmation: 'Are you sure you want to demote this Admin to Member?',
      join: 'Join Community',
      leave: 'Leave Community',
      delete: 'Delete Community',
      edit: 'Edit Community',
      inviteOthers: 'Invite others',
      acceptJoinMemberRequest: 'Approve request',
      acceptJoinMemberRequestConfirmation: 'Are you sure you want to Approve this user\'s request?',
      refuseJoinMemberRequest: 'Deny request',
      refuseJoinMemberRequestConfirmation: 'Are you sure you want to Deny this user\'s request?',
    },

    members: {
      invite: {
        title: 'Add Members',
        button: 'Add',
      },
      manage: {
        deleteConfirmation: 'Are you sure you want to remove this member from the community?',
      },
    },

    newStatusLabel: 'New',
    viewDetails: 'View Details',
    share: 'Share',
    channels: {
      create: {
        title: 'New Channel',
        buttonCreate: 'Create',
      },
      edit: {
        title: 'Edit Channel',
        deleteConfirmation: 'Are you sure you want to delete this channel?',
        deleteMemberConfirmation: 'Are you sure you want to delete this member from the channel?',
        acceptJoinMemberRequest: 'Approve request',
        acceptJoinMemberRequestConfirmation: 'Are you sure you want to Approve this user\'s request?',
        refuseJoinMemberRequest: 'Deny request',
        refuseJoinMemberRequestConfirmation: 'Are you sure you want to Deny this user\'s request?',
        leaveConfirmation: 'Are you sure you want to leave this channel?',
        invite: 'Add Members',
        leave: 'Leave Channel',
        edit: 'Edit Channel',
        delete: 'Delete Channel',
        buttonDeleteMember: 'Delete Member',
        muteChannel: 'Mute Channel',
        unmuteChannel: 'Unmute Channel',
        createEvent: 'Create Event',
        editEvent: 'Edit Event',
      },
    },
  },

  events: {
    list: {
      title: 'Events',
    },

    create: {
      title: 'New Event',
      buttonCreate: 'Create',
    },

    edit: {
      title: 'Edit Event',
      buttonEdit: 'Save',
      buttonDelete: 'Delete',
      deleteConfirmation: 'Are you sure you want to delete this event?',
      join: 'Join and RSVP',
      eventLinkCopied: 'Event Link copied',
    },
  },

  messaging: {
    list: {
      title: 'Inbox',
    },

    create: {
      title: 'New Message',
      buttonCreate: 'Start chat',

      selectedMembersTitle: 'Selected participants',
    },

    edit: {
      title: 'Edit Chat',
      buttonEdit: 'Save',
      buttonDelete: 'Delete',
      deleteConfirmation: 'Are you sure you want to delete this chat?',
      archiveConfirmation: 'Are you sure you want to archive this chat?',
      unarchiveConfirmation: 'Are you sure you want to unarchive this chat?',
    },
  },

  notifications: {
    list: {
      title: 'Notifications',
    },
    proximityAlert: {
      title: 'Proximity Alerts',
      newAlertTitle: 'New Proximity Alert',
      peopleNearby: (peopleAmount: number) => `${peopleAmount} people will be nearby`,
      willBeDays: (days: number) => `in ${days} ${days === 1 ? 'day' : 'days'}`,
    },

    typeTitles: {
      newConnection: (user: string) => <React.Fragment><strong>{user}</strong> invited you to their network</React.Fragment>,
      connectionAccepted: (user: string) => <React.Fragment><strong>{user}</strong> accepted your connection</React.Fragment>,
      newConfirmedTrip: (user: string, location: string, from: string, to: string) => <React.Fragment><strong>{user}</strong> will be in <strong>{location}</strong> from {from} to {to}.</React.Fragment>,
      newPlannedTrip: (user: string, location: string, from: string, to: string) => <React.Fragment><strong>{user}</strong> plans to be in <strong>{location}</strong> from {from} to {to}. Connect to join {user}'s trip.</React.Fragment>,
      newImportedTrip: 'A new trip was detected. Your network will be notified about it within 24h.',
      newImportedTrips: (amount: string) => <React.Fragment>We created {amount} new trips. Your network will be notified about them within 24h.</React.Fragment>,
      contactJoined: (user: string) => <React.Fragment><strong>{user}</strong> just joined and was added to your general network.</React.Fragment>,
      newCommunityInvitation: (user: string, community: string) => <React.Fragment><strong>{user}</strong> invited you to join <strong>{community}</strong></React.Fragment>,
      connectionBackInTown: (user: string, city: string = 'town') => <React.Fragment><strong>{user}</strong> is back in <strong>{city}</strong>.</React.Fragment>,
      contactConnected: (user: string) => <React.Fragment><strong>{user}</strong> matched your contacts and was added to your general network.</React.Fragment>,
      privateChannelJoinRequest: (user: string, channel: string, community: string) => <React.Fragment><strong>{user}</strong> has requested to join <strong>{channel}</strong> in <strong>{community}</strong></React.Fragment>,
      privateChannelRequestApproved: (community: string, channel: string) => <React.Fragment>A Community Admin has accepted your request to join <strong>{channel}</strong> in <strong>{community}</strong></React.Fragment>,
      communityJoinRequest: (community: string, user: string) => <React.Fragment><strong>{user}</strong> has requested to join <strong>{community}</strong></React.Fragment>,
      communityJoinRequestApproved: (community: string) => <React.Fragment>A Community Admin has approved your request to join <strong>{community}</strong></React.Fragment>,
      communityJoinRequestDenied: (community: string) => <React.Fragment>A Community Admin has denied your request to join <strong>{community}</strong></React.Fragment>,
      addedToChannel: (user: string, channel: string, community: string) => <React.Fragment><strong>{user}</strong> added you to <strong>{channel}</strong> in <strong>{community}</strong></React.Fragment>,
      peerInLocationSoon: (user: string, city: string) => <React.Fragment><strong>{user}</strong> will be in {city} in just 2 days. Don't miss out on the chance to meet up!</React.Fragment>,
    },
    typeButtons : {
      newConnection: 'Accept',
    },
  },

  integrations: {
    list: {
      title: 'Integrations',
    },
  },

  search: {
    locations: 'Locations',
    users: 'Network',
    communities: 'Communities',
  },

  menu: {
    auth: {
      login: 'Log In',
      logout: 'Log Out',
      signUp: 'Signup',
    },
    dashboard: 'Here & There',
    trips: {
      list: 'Trips',
    },
    users: {
      list: 'Network',
      contacts: 'Contacts',
      accountSettings: 'Account',
      privacy: 'Privacy',
      messages: 'Inbox',
      notifications: 'Notifications',
    },
    communities: {
      list: 'Communities',
    },
    search: 'Search',
    integrations: 'Integrations',
    term: 'Terms of Service',
    privacy: 'Privacy Policy',
    feedback: 'Send Feedback',
  },

  forms: {
    fieldEmail: 'Email',
    fieldPhone: 'Phone',
    fieldPassword: 'Password',
    labelOptional: '(Optional)',
    buttonCreate: 'Create',
    buttonEdit: 'Save',
    buttonDelete: 'Delete',
    fieldNote: 'Notes',
    fieldAddress: 'Address',

    signup: {
      fieldRepeatPassword: 'Repeat Password',
    },
    phone: {
      fieldSMSCode: 'SMS code',
      buttonResendSMSCode: 'Resend SMS code',
    },
    user: {
      fieldName: 'Name',
      fieldLastName: 'Last name',
      fieldType: 'Type',
      fieldCompany: 'Company',
      fieldPosition: 'Position',
      fieldPicture: 'Upload picture',
      fieldSurname: 'Last name',
      fieldEmailDisabledHelper: 'Please contact support to change the email.',
      fieldBio: 'Bio',
      fieldUsername: 'Username',
      fieldBaseLocation: 'Home city',
      labelSocials: 'Social media accounts',
      fieldSocialUrl: 'Handle',
      fieldBookMeetingLink: 'Book Meeting Link',

      visibility: {
        profileVisibilityField: 'Profile',
        locationVisibilityField: 'Location',
        surnameVisibilityField: 'Last name',
        phoneVisibilityField: 'Phone',
        emailVisibilityField: 'Email',
        linkedinVisibilityField: 'LinkedIn',
        twitterVisibilityField: 'Twitter',
        instagramVisibilityField: 'Instagram',
      },

      notifications: {
        pushNotifications: 'Push notifications',
        emailNotificationsField: 'Email notifications',
        smsNotificationsField: 'SMS messages',
      },
    },
    contact: {
      fieldName: 'Name',
      fieldLastName: 'Last name',
      fieldLocation: 'Location',
      fieldMobilePhone: 'Mobile phone',
      fieldAddMembersPlaceholder: 'Search more contacts...',
      fieldPicture: 'Upload picture',
    },
    trip: {
      fieldLocation: 'City',
      fieldDescription: 'Description',
      fieldStartDate: 'Arrival date',
      fieldEndDate: 'Departure date',
      fieldDates: 'Date range',
      fieldVisibility: 'Visibility',

      notification: {
        contactsListField: (tripDestination: string) => `Select which contacts you want to notify in ${tripDestination}`,
      },
    },
    community: {
      fieldName: 'Name',
      fieldDescription: 'Description',
      fieldMembers: 'Members',
      fieldAddMembers: 'Members',
      fieldAddMembersPlaceholder: 'Search users...',
      fieldPicture: 'Upload picture',
      fieldRequests: 'Requests',
      fieldHandle: 'Handle',
    },
    communityChannel: {
      fieldName: 'Name',
      fieldMembers: 'Members',
      fieldAddMembers: 'Add members',
      fieldAddMembersPlaceholder: 'Search for community members...',
      fieldRequests: 'Requests',
      settings: 'Settings',
    },
    event: {
      fieldLocation: 'City',
      fieldName: 'Name',
      fieldDescription: 'Description',
      fieldStartDate: 'Start',
      fieldEndDate: 'End',
    },
    chat: {
      fieldMessage: 'Message',
      fieldMembers: 'Participants',
      fieldAddMembers: 'Select participants',
      fieldAddMembersPlaceholder: 'Search users...',
    },

    validations: {
      required: 'This field is required',
      invalidDate: 'Date is invalid',
      invalidEmail: 'Email is invalid',
      invalidPhone: 'Phone number is invalid',
      invalidYear: 'The year is incorrect',
      invalidPassword: 'Password is invalid. At least 1 number is required.',
      invalidFileSize: (max: string) => `File is too big. Maximum is ${max}`,
      invalidFileType: 'File type is not valid',
      minLength: (length: number | string) => `Password must be at least ${length} characters long`,
      maxLength: (length: number | string) => `Can't have more than ${length} characters`,
      oneOrMoreFieldsAreIncorrect: 'One or more fields are incorrect.',
      memberInvitationAllEmailsValid: 'One or more emails is invalid.',
      usernameNoSpaces: 'Username should not contain spaces',
      usernameStartWithLetter: 'Username should start with a letter',
      usernameTaken: 'This username is already taken',
      invalidUserName: 'Username can only contain letters, numbers, and underscores',
      locationSameAsBase: 'Trip destination cannot be the same as base location',
      invalidSMSCodeLength: 'SMS code must be exactly 6 digits',
      invalidLink: 'Link is invalid',
      invalidCommunityHandle: 'Community handle can only contain letters, underscores, periods and commas',
      communityHandleTaken: 'This Community handle is already taken',
      eventNameTaken: 'This event name is already taken',
    },
  },

  filters: {
    fieldSearch: 'Search',
  },

  feedback: {
    changesSaved: 'Changes saved successfully',
  },

  dialogs: {
    defaultTitle: 'Are you sure you want to do this?',
    defaultContent: 'This action is irreversible.',
    buttonNo: 'No',
    buttonYes: 'Yes',
    buttonCancel: 'Cancel',
    buttonClose: 'Close',

    nativePrompt: {
      title: 'Continue in...',
      app: 'Thr. App',
      browser: 'Browser',
      buttonOpen: 'Open',
      buttonContinue: 'Continue',
      buttonOk: 'Ok',
    },
  },

  navigation: {
    buttonGoBack: 'Go back',
  },

  errors: {
    noPagePermission: 'You are not allowed to view this page',
    somethingWentWrong: 'Something went wrong!',
  },

  routes: {

  },

  countries: {
    US: 'United States',
    AF: 'Afghanistan',
    AX: 'Aland Islands',
    AL: 'Albania',
    DZ: 'Algeria',
    AS: 'American Samoa',
    AD: 'Andorra',
    AO: 'Angola',
    AI: 'Anguilla',
    AQ: 'Antarctica',
    AG: 'Antigua And Barbuda',
    AR: 'Argentina',
    AM: 'Armenia',
    AW: 'Aruba',
    AU: 'Australia',
    AT: 'Austria',
    AZ: 'Azerbaijan',
    BS: 'Bahamas',
    BH: 'Bahrain',
    BD: 'Bangladesh',
    BB: 'Barbados',
    BY: 'Belarus',
    BE: 'Belgium',
    BZ: 'Belize',
    BJ: 'Benin',
    BM: 'Bermuda',
    BT: 'Bhutan',
    BO: 'Bolivia',
    BQ: 'Bonaire, Sint Eustatius and Saba',
    BA: 'Bosnia And Herzegovina',
    BW: 'Botswana',
    BV: 'Bouvet Island',
    BR: 'Brazil',
    IO: 'British Indian Ocean Territory',
    BN: 'Brunei Darussalam',
    BG: 'Bulgaria',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    KH: 'Cambodia',
    CM: 'Cameroon',
    CA: 'Canada',
    CV: 'Cape Verde',
    KY: 'Cayman Islands',
    CF: 'Central African Republic',
    TD: 'Chad',
    CL: 'Chile',
    CN: 'China',
    CX: 'Christmas Island',
    CC: 'Cocos (Keeling) Islands',
    CO: 'Colombia',
    KM: 'Comoros',
    CG: 'Congo',
    CD: 'Congo, Democratic Republic of the',
    CK: 'Cook Islands',
    CR: 'CostaRica',
    CI: 'Cote d\'Ivoire',
    HR: 'Croatia',
    CU: 'Cuba',
    CW: 'Curacao',
    CY: 'Cyprus',
    CZ: 'Czech Republic',
    DK: 'Denmark',
    DJ: 'Djibouti',
    DM: 'Dominica',
    DO: 'Dominican Republic',
    EC: 'Ecuador',
    EG: 'Egypt',
    SV: 'El Salvador',
    GQ: 'Equatorial Guinea',
    ER: 'Eritrea',
    EE: 'Estonia',
    ET: 'Ethiopia',
    FK: 'Falkland Islands',
    FO: 'Faroe Islands',
    FJ: 'Fiji',
    FI: 'Finland',
    FR: 'France',
    GF: 'French Guiana',
    PF: 'French Polynesia',
    TF: 'French Southern Territories',
    GA: 'Gabon',
    GM: 'Gambia',
    GE: 'Georgia',
    DE: 'Germany',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GR: 'Greece',
    GL: 'Greenland',
    GD: 'Grenada',
    GP: 'Guadeloupe',
    GU: 'Guam',
    GT: 'Guatemala',
    GG: 'Guernsey',
    GN: 'Guinea',
    GW: 'GuineaBissau',
    GY: 'Guyana',
    HT: 'Haiti',
    HM: 'Heard Island and McDonald Islands',
    VA: 'Holy See (Vatican City State)',
    HN: 'Honduras',
    HK: 'Hong Kong',
    HU: 'Hungary',
    IS: 'Iceland',
    IN: 'India',
    ID: 'Indonesia',
    IR: 'Iran',
    IQ: 'Iraq',
    IE: 'Ireland',
    IM: 'IsleOfMan',
    IL: 'Israel',
    IT: 'Italy',
    JM: 'Jamaica',
    JP: 'Japan',
    JE: 'Jersey',
    JO: 'Jordan',
    KZ: 'Kazakhstan',
    KE: 'Kenya',
    KI: 'Kiribati',
    KR: 'Korea, Republic of',
    KP: 'Korea, Democratic People\'s Republic of',
    XK: 'Kosovo',
    KW: 'Kuwait',
    KG: 'Kyrgyzstan',
    LA: 'Lao People\'s Democratic Republic',
    LV: 'Latvia',
    LB: 'Lebanon',
    LS: 'Lesotho',
    LR: 'Liberia',
    LY: 'Libya',
    LI: 'Liechtenstein',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    MO: 'Macao',
    MK: 'Macedonia',
    MG: 'Madagascar',
    MW: 'Malawi',
    MY: 'Malaysia',
    MV: 'Maldives',
    ML: 'Mali',
    MT: 'Malta',
    MH: 'Marshall Islands',
    MQ: 'Martinique',
    MR: 'Mauritania',
    MU: 'Mauritius',
    YT: 'Mayotte',
    MX: 'Mexico',
    FM: 'Micronesia',
    MD: 'Moldova',
    MC: 'Monaco',
    MN: 'Mongolia',
    ME: 'Montenegro',
    MS: 'Montserrat',
    MA: 'Morocco',
    MZ: 'Mozambique',
    MM: 'Myanmar',
    NA: 'Namibia',
    NR: 'Nauru',
    NP: 'Nepal',
    NL: 'Netherlands',
    NC: 'NewCaledonia',
    NZ: 'NewZealand',
    NI: 'Nicaragua',
    NE: 'Niger',
    NG: 'Nigeria',
    NU: 'Niue',
    NF: 'Norfolk Island',
    MP: 'Northern Mariana  Islands',
    NO: 'Norway',
    OM: 'Oman',
    PK: 'Pakistan',
    PW: 'Palau',
    PS: 'Palestine, State of',
    PA: 'Panama',
    PG: 'Papua New Guinea',
    PY: 'Paraguay',
    PE: 'Peru',
    PH: 'Philippines',
    PN: 'Pitcairn',
    PL: 'Poland',
    PT: 'Portugal',
    PR: 'Puerto Rico',
    QA: 'Qatar',
    RE: 'Reunion',
    RO: 'Romania',
    RU: 'Russian Federation',
    RW: 'Rwanda',
    BL: 'Saint Barthelemy',
    SH: 'Saint Helena',
    KN: 'Saint Kitts And Nevis',
    LC: 'Saint Lucia',
    MF: 'Saint Martin',
    PM: 'Saint Pierre And Miquelon',
    VC: 'Saint Vincent and the Grenadines',
    WS: 'Samoa',
    SM: 'San Marino',
    ST: 'Sao Tome and Principe',
    SA: 'SaudiArabia',
    SN: 'Senegal',
    RS: 'Serbia',
    SC: 'Seychelles',
    SL: 'Sierra Leone',
    SG: 'Singapore',
    SX: 'Sint Maarten',
    SK: 'Slovakia',
    SI: 'Slovenia',
    SB: 'Solomon Islands',
    SO: 'Somalia',
    ZA: 'SouthAfrica',
    GS: 'South Georgia and the South Sandwich Islands',
    SS: 'South Sudan',
    ES: 'Spain',
    LK: 'Sri Lanka',
    SD: 'Sudan',
    SR: 'Suriname',
    SJ: 'Svalbard and Jan Mayen',
    SZ: 'Swaziland',
    SE: 'Sweden',
    CH: 'Switzerland',
    SY: 'Syrian Arab Republic',
    TW: 'Taiwan',
    TJ: 'Tajikistan',
    TZ: 'Tanzania',
    TH: 'Thailand',
    TL: 'TimorLeste',
    TG: 'Togo',
    TK: 'Tokelau',
    TO: 'Tonga',
    TT: 'Trinidad and Tobago',
    TN: 'Tunisia',
    TR: 'Turkey',
    TM: 'Turkmenistan',
    TC: 'Turks and Caicos Islands',
    TV: 'Tuvalu',
    UG: 'Uganda',
    UA: 'Ukraine',
    AE: 'United Arab Emirates',
    GB: 'United Kingdom',
    UM: 'United States Minor Outlying Islands',
    UY: 'Uruguay',
    UZ: 'Uzbekistan',
    VU: 'Vanuatu',
    VE: 'Venezuela',
    VN: 'Vietnam',
    VG: 'British Virgin Islands',
    VI: 'US Virgin Islands',
    WF: 'Wallis and Futuna',
    EH: 'Western Sahara',
    YE: 'Yemen',
    ZM: 'Zambia',
    ZW: 'Zimbabwe',
  },
};

export default dictionary;
