import { isEmpty } from 'lodash';
import { isToday } from 'date-fns';

import { camelizeObject } from '@cvt/helpers/object';

import { mapUserData  } from '@modules/Users/client/userClient.formatter';
import { mapContactData } from '@modules/Contacts/client/contactClient.formatter';
import { mapTripsData } from '@modules/Trips/client/tripClient.formatter';
import { mapCommunityData } from '@modules/Communities/client/communityClient.formatter';

import { getPictures } from '@shared/helpers/media';
import { isNative } from '@shared/helpers/environment';
import { userClient } from '@modules/Users/client/userClient';

export const mapUserAuthData = (data: Auth.MeApi): Auth.Me => {
  // @ts-ignore
  const { company, ...userData } = data;
  let user = camelizeObject<Auth.Me>(userData);
  const profilePictures = getPictures(user.profilePicture);
  let trips: Trips.Trip[]  = [];

  let todayLocation = user.baseLocation;

  if (data.trip) {
    trips = mapTripsData([data.trip]);
  } else if (data.trips) {
    if (Array.isArray(data.trips)) {
      trips = mapTripsData(data.trips);
    } else if (!isEmpty(data.trips)) {
      trips = mapTripsData([data.trips]);
    }
  }

  const trip = trips[0] || null;

  if (trip && isToday(trip.arrivalDatetime)) {
    todayLocation = trip.location;
  }

  // HACKY FIX
  if (isNative() && user.userType === 'subscriber') {
    user = {
      ...user,
      userType: 'pro',
    };
    userClient.editUser(user);
  }

  return {
    ...user,
    displayName: `${user.firstName} ${user.lastName}`,
    profilePicture: profilePictures?.[100],
    profilePictureSizes: profilePictures,
    trips: trips || [],
    trip,
    todayLocation,
  };
};


export const mapInviteData = (data: Auth.UserInviteApi): Auth.UserInvite => {
  return {
    id: data.id,
    contact: mapContactData(data.contact),
    personInviting: mapUserData(data.person_inviting),
  };
};

export const mapCommunityInvitationData = (data: Auth.CommunityInvitationApi): Auth.CommunityInvitation => {
  return {
    ...data,
    community: mapCommunityData(data.community),
  };
};