import React from 'react';
import { Divider, List, ListItem, ListItemProps, ListProps } from '@mui/material';

import { BodyLoading } from '@cvt/components/layout/BodyLoading';
import { MessageFeedbackView } from '@cvt/components/MessageFeedbackView';

import { darkBlue } from '@shared/theme/palette';

import { useTrips } from '../hooks/useTrips';

export interface Props {
  renderItem: (trip: Trips.Trip) => React.ReactNode;
  renderSkeleton?: () => React.ReactNode;
  filters?: Trips.GetListParams;
  slotProps?: {
    list?: ListProps;
    listItem?: ListItemProps;
  },
  showDivider?: boolean;
}

export const TripsList: React.FC<Props> = ({ filters, renderItem, renderSkeleton, slotProps, showDivider = false }) => {
  const { trips, status } = useTrips(filters);

  if (status === 'loading' || !trips) {
    if (renderSkeleton) {
      return (
        <React.Fragment>
          <List {...slotProps?.list}>
            {Array.from({ length: 3 }).map((v, idx) => (
              <React.Fragment key={idx}>
                {showDivider && (<Divider component="li"/>)}
                <ListItem {...slotProps?.listItem}>{renderSkeleton()}</ListItem>
              </React.Fragment>
            ))}
          </List>
        </React.Fragment>
      );
    }

    return <BodyLoading height="100%"/>;
  }


  if (trips.length === 0) {
    return <MessageFeedbackView 
      message="No trips" 
      height="100%" 
      slotProps={{
        typography: {
          sx: {
            fontSize: 23,
            color: darkBlue,
          },
        },
      }}
    />;
  }

  return (
    <React.Fragment>
      <List {...slotProps?.list}>
        {trips.map(trip => (
          <React.Fragment key={trip.id}>
            {showDivider && (<Divider component="li"/>)}
            <ListItem {...slotProps?.listItem}>{renderItem(trip)}</ListItem>
          </React.Fragment>
        ))}
      </List>
    </React.Fragment>
  );
};
