import React from 'react';
import { Alert, Box, Grid, Paper, Stack, Typography } from '@mui/material';

import { DialogContext } from '@cvt/contexts';
import { MessageFeedbackView } from '@cvt/components/MessageFeedbackView';
import { BodyLoading } from '@cvt/components/layout/BodyLoading';
import { useDictionary } from '@cvt/hooks/useDictionary';

import { formatTripDates } from '@modules/Trips/helpers/date';

import { useTrip } from '../hooks/useTrip';
import { useTripCrud } from '../hooks/useTripCrud';
import { TripNotificationForm, FormCrud as TripNotificationFormData } from '../partials/TripNotificationForm';

interface Props extends Trips.Trip {
  onComplete?: () => void;
}

const TripNotificationView: React.FC<Props> = ({ onComplete, ...trip }) => {
  const { asyncConfirmation } = React.useContext(DialogContext);

  const dictionary = useDictionary();

  const { patchTrip } = useTripCrud();

  const onSubmit = React.useCallback(async (data: TripNotificationFormData) => {
    const contactsToNotify = data.contacts.map(contact => contact.id);

    await patchTrip({
      ...trip,
      id: trip.id,
      contactsToNotify,
    });

    const complete = await asyncConfirmation({ title: dictionary.trips.edit.notifiedContacts, content: ' ', cancelLabel: '', confirmLabel: dictionary.dialogs.nativePrompt.buttonOk });

    if (complete) {
      onComplete && onComplete();
    }
  }, [asyncConfirmation, dictionary, patchTrip, onComplete, trip]);

  return (
    <Box display="flex" flexDirection="column" width="100%" height="100%">
      <Grid container spacing={2} justifyContent="center" mt={{ xs: 2, sm: 0 }}>
        <Grid item>
          <Typography variant="h1">{dictionary.trips.edit.upcomingTrip}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" align="center" mb={2} px={1}>{dictionary.forms.trip.notification.contactsListField(trip.location.title.replace(/,.*/, ''))}</Typography>
          <Paper component={Box} p={2}>
            {trip.status === 'from_gmail' && (
              <Box mb={3}>
                <Alert color="info" icon={false}>{dictionary.trips.generatedAlert}</Alert>
              </Box>
            )}
            <Stack direction="row" spacing={1} alignItems="center" maxWidth="100%" mb={4}>
              <Typography variant="body1" component="span" fontWeight={500} width="100%" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">{trip.location.title}</Typography>
              <Typography variant="body2" component="span" whiteSpace="nowrap">
                {formatTripDates(trip.arrivalDatetime, trip.departureDatetime)}
              </Typography>
            </Stack>
            
            <TripNotificationForm
              fieldProps={{
                contacts: {
                  filters: {
                    limit: 100,
                    location: trip.location.id.toString(),
                  },
                },
              }}
              onSubmitRequest={onSubmit}
              onSubmitButtonText={dictionary.trips.edit.buttonNotify}
            />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export const TripNotification: React.FC<{ id: string, onComplete?: () => void }> = ({ id, onComplete }) => {
  const { trip, status } = useTrip({
    id: Number(id),
  });

  if ([status].includes('loading')) {
    return <BodyLoading height="100%" />;
  }

  if ([status].includes('error') || !trip) {
    return <MessageFeedbackView height="100%" />;
  }

  return <TripNotificationView {...trip} onComplete={onComplete} />;
};