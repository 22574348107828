import React from 'react';
import {
  Typography,
  Paper,
  Box,
  Stack,
  Chip,
  Skeleton,
  useTheme,
} from '@mui/material';

import { useDictionary } from '@cvt/hooks/useDictionary';

import { isCreatedRecently } from '@shared/helpers/date';
import { darkBlue } from '@shared/theme/palette';

import { formatTripDates } from '../../../helpers/date';

export const SubscribeTripRow: React.FC<Trips.Trip> = trip => {
  const dictionary = useDictionary();
  const theme = useTheme();

  const isNew = React.useMemo(() => isCreatedRecently(trip.createdAt), [trip.createdAt]);

  const isScheduled = React.useMemo(() => {
    return trip.status === 'from_gmail';
  }, [trip.status]);

  return (
    <Paper
      component={Box}
      width="100%"
      variant="transparent"
    >
      <Box component="section" py={1} px={2} sx={{ cursor: 'pointer' }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" minHeight={41} spacing={2}>
          <Stack direction="column">
            <Typography variant="h4" fontWeight={500} color={theme.palette.mode === 'dark' ? 'secondary' : 'primary'}>
              {trip.location.title}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1} flexShrink={0}>
            <Typography variant="body1" color={theme.palette.mode === 'dark' ? 'secondary' : 'primary'}>
              {formatTripDates(trip.arrivalDatetime, trip.departureDatetime)}
            </Typography>

            {isScheduled && (
              <Chip
                label={dictionary.trips.generatedStatusLabel}
                size="small"
                color="primary"
              />
            )}

            {isNew && !isScheduled && (
              <Chip
                label={dictionary.trips.newStatusLabel}
                size="small"
                color={theme.palette.mode === 'dark' ? 'secondary' : 'primary'}
              />
            )}
          </Stack>
        </Stack>
      </Box>
    </Paper>
  );
};

export const SubscribeTripRowSkeleton: React.FC = () => {
  return (
    <Paper component={Box} width="100%" variant="transparent">
      <Box component="section" py={1} pl={2} pr={1}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Typography variant="body1" component="h1" fontWeight={500}><Skeleton variant="text" width={180} sx={{ backgroundColor: darkBlue }}/></Typography>
          <Box height={34} />
          <Stack direction="row" alignItems="center" spacing={1} flexShrink={0}>
            <Typography variant="body2" color="secondary"><Skeleton variant="text" width={80} sx={{ backgroundColor: darkBlue }}/></Typography>
          </Stack>
        </Stack>
      </Box>
    </Paper>
  );
};
