import { AxiosResponse } from 'axios';

import { request } from '@cvt/clients/baseClient';
import { snakizeObject } from '@cvt/helpers/object';

import { mapTripCrudData, mapTripData, mapTripsData } from './tripClient.formatter';

const tripsApiBaseUrl = import.meta.env.VITE__CVT_API_URL;

const getTrip = (params: { id: number | string }): Promise<AxiosResponse<Trips.Trip>> => {
  return request({
    options: {
      url: `${tripsApiBaseUrl}/trips/${params.id}/`,
      method: 'GET',
    },
  }).then((data: AxiosResponse<Trips.TripApi>) => ({
    ...data,
    data: mapTripData(data.data),
  }));
};

const getTrips = (params: Trips.GetListParams): Promise<AxiosResponse<CVT.Query.PaginatedResults<Trips.Trip>>> => {
  const now = new Date().toISOString();
  return request({
    options: {
      url: `${tripsApiBaseUrl}/trips/`,
      method: 'GET',
      params: {
        date_gte: now,
        ...snakizeObject(params),
      },
    },
  }).then((data: AxiosResponse<CVT.Query.PaginatedResults<Trips.TripApi>>) => ({
    ...data,
    data: {
      ...data.data,
      results: mapTripsData(data.data.results),
    },
  }));
};

const createTrip = (data: Trips.Create): Promise<AxiosResponse<Trips.Trip>> => {
  return request({
    options: {
      url: `${tripsApiBaseUrl}/trips/`,
      method: 'POST',
      data: mapTripCrudData(data),
    },
  }).then((data: AxiosResponse<Trips.TripApi>) => ({
    ...data,
    data: mapTripData(data.data),
  }));
};

const editTrip = (data: Trips.Edit): Promise<AxiosResponse<Trips.Trip>> => {
  return request({
    options: {
      url: `${tripsApiBaseUrl}/trips/${data.id}/`,
      method: 'PUT',
      data: mapTripCrudData(data),
    },
  }).then((data: AxiosResponse<Trips.TripApi>) => ({
    ...data,
    data: mapTripData(data.data),
  }));
};

const patchTrip = (data: Partial<Trips.Edit>): Promise<AxiosResponse<Trips.Trip>> => {
  return request({
    options: {
      url: `${tripsApiBaseUrl}/trips/${data.id}/`,
      method: 'PATCH',
      // @ts-ignore
      data: mapTripCrudData(data),
    },
  }).then((data: AxiosResponse<Trips.TripApi>) => ({
    ...data,
    data: mapTripData(data.data),
  }));
};

const deleteTrip = (params: { id: number | string }): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${tripsApiBaseUrl}/trips/${params.id}/`,
      method: 'DELETE',
    },
  });
};

export const tripsClient = {
  getTrip,
  getTrips,
  createTrip,
  editTrip,
  patchTrip,
  deleteTrip,
};
