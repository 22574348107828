import React from 'react';
import {
  Typography,
  Stack,
  Box,
  BoxProps,
  Skeleton,
  Button,
  Avatar,
} from '@mui/material';
import { Circle, EmailTwoTone, Telegram, Textsms } from '@mui/icons-material';

import { useRouter } from '@cvt/hooks/useRouter';
import { FeedbackContext } from '@cvt/contexts';
import { useDictionary } from '@cvt/hooks/useDictionary';

import { isNative } from '@shared/helpers/environment';
import { copyToClipboard } from '@shared/helpers/navigator';
import { getDateDotColor } from '@shared/helpers/date';
import { blue, darkBlue, primary } from '@shared/theme/palette';

import { Authenticated } from '@modules/Auth/components';
import { sendSms } from '@modules/Contacts/helpers/mobile';
import { useChatCrud } from '@modules/Messaging/hooks/useChatCrud';
import { formatTripDates } from '@modules/Trips/helpers/date';

import { UserContext } from '../contexts';

interface Props extends BoxProps {
  user: Users.User;
  showContactActions?: boolean;
}

export const UserLargeCard: React.FC<Props> = ({ user, showContactActions = true, ...props }) => {

  const { user: me } = React.useContext(UserContext);
  const { triggerFeedback } = React.useContext(FeedbackContext);

  const dictionary = useDictionary();
  const router = useRouter();

  const { createChat } = useChatCrud();

  const isUserConnected = React.useMemo(() => (user.connection?.type === 'general' || user.connection?.type === 'close') && user.connection?.status === 'active', [user]);

  const isTripHere = React.useMemo(() => {
    if (user.trip && me?.todayLocation) {
      return user.trip.location.id.toString() === me.todayLocation?.id.toString();
    }
    return false;
  }, [user.trip, me]);

  const circleColor = React.useMemo(() => {
    return user.trip ? getDateDotColor(user.trip.arrivalDatetime, user.trip.departureDatetime) : undefined;
  }, [user.trip]);

  const onChatRequest = React.useCallback(async (e: any) => {
    e.stopPropagation();
    const response = await createChat({
      users: [user.id],
    });
    router.inbox.view(response.data.id).go();
  }, [createChat, user, router]);

  const handleCopyToClipboard = async (text: string, feedbackText: string) => {
    await copyToClipboard(text);

    triggerFeedback({
      severity: 'success',
      message: feedbackText,
    });
  };

  const onEmailRequest = React.useCallback(async (e: any) => {
    e.stopPropagation();
    if (user) {
      window.location.href = `mailto:${user?.email}`;
    }
  }, [user]);

  const onSendMessage = React.useCallback(async () => {
    sendSms(`${user?.phone}`);
  }, [user?.phone]);

  return (
    <Box
      height="100%"
      borderRadius={4}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      bgcolor="#aee0f4"
      color="#3f6781"
      {...props}
    >
      <Box>
        {user && (
          <Box
            display="flex"
            flexDirection="column"
            borderRadius={4}
          >
            <Box display="flex" justifyContent="center" alignItems="center" px={2} pt={2}>
              <Avatar
                src={user.profilePictureSizes?.[100]}
                alt={user.displayName}
                sx={{ 
                  width: 130, 
                  height: 130,
                  border: 10,
                  borderColor: primary,
                }}
              >
                {user.firstName[0]}{user.lastName[0]}
              </Avatar>
            </Box>
            <Box width="100%" p={1}>
              <Stack width="100%" direction="row" justifyContent="center">
                <Stack mt="auto" alignItems="center">
                  <Typography variant="h1" fontSize={32} fontWeight={600} textAlign="center">
                    {user.displayName}
                  </Typography>
                  {user.trip?.arrivalDatetime && user.trip?.departureDatetime ? (
                    <React.Fragment>
                      {isTripHere && (
                        <Stack width="100%" direction="row" alignItems="center" justifyContent="center" spacing={1}>
                          <Typography variant="body1" fontWeight={500} noWrap>
                          Here between {formatTripDates(user.trip?.arrivalDatetime, user.trip?.departureDatetime)}
                          </Typography>
                        </Stack>
                      )}
                      {!isTripHere && (
                        <Stack width="100%" direction="row" alignItems="center" justifyContent="center" spacing={1}>
                          <Typography variant="body1" fontWeight={500} noWrap>
                            <strong>{user.trip.location.title}</strong> between {formatTripDates(user.trip?.arrivalDatetime, user.trip?.departureDatetime)}
                          </Typography>
                        </Stack>
                      )}
                    </React.Fragment>
                  ) : (
                    <Typography variant="body1" fontWeight={500} noWrap>
                    Currently in {user.baseLocation?.title || 'Unknown'}
                    </Typography>
                  )}
                </Stack>
              </Stack>
            </Box>
          </Box>
        )}
        <Stack direction="row" alignItems="center" justifyContent="center" spacing={1} pt={1} pb={2}>
          <Button variant="contained" color="secondary" onClick={onChatRequest} sx={{ backgroundColor: '#3f6781' }}>
            DM
          </Button>
          {user.email && (
            <Button variant="contained" color="secondary" onClick={onEmailRequest} sx={{ backgroundColor: '#3f6781' }}>
              Email
            </Button>
          )}
          {user.phone && isNative() && (
            <Button onClick={onSendMessage} variant="contained" color="secondary" sx={{ backgroundColor: '#3f6781' }}>Text</Button>
          )}
        </Stack>
      </Box>
    </Box>
  );
};

export const UserLargeCardSkeleton: React.FC = () => {
  return (
    <Box
      height="100%"
      borderRadius={4}
      display="flex"
      sx={{
        backgroundColor: blue,
        overflow: 'hidden',
      }}
    >
      <Skeleton variant="rectangular" height="100%" width="100%" />
    </Box>
  );
};
