import React from 'react';
import { BoxProps, Grid } from '@mui/material';

import { TripsList } from '../../partials/TripsList';
import { SubscribeTripRow, SubscribeTripRowSkeleton } from './components/SubscribeTripRow';

interface Props extends BoxProps {
  params?: Partial<Trips.ListParams>;
}

export const SubscribeTrips: React.FC<Props> = ({ params }) => {
  return (
    <Grid height="10%" item xs={12}>
      <TripsList
        filters={{
          ...params,
          limit: 100,
        }}
        renderItem={trip => <SubscribeTripRow {...trip} />}
        renderSkeleton={() => <SubscribeTripRowSkeleton />}
        slotProps={{
          list: {
            disablePadding: true,
          },
          listItem: {
            disableGutters: true,
            dense: true,
          },
        }}
      />
    </Grid>
  );
};
